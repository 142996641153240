/**
 *
 * StatsSpares
 *
 */
import * as React from 'react';
import { FrameSummary } from '../GameManager/slice/types';
import { StatCard } from '../StatCard';
import { isSplit } from 'utils/isSplit';
import { SvgIcon, useTheme } from '@mui/material';
import { splitStats } from 'utils/stats';

interface Props {
  frames: FrameSummary[][];
}

export function StatsSplits(props: Props) {
  const { frames } = props;
  const theme = useTheme();
  const { made, attempts } = splitStats(frames);
  return (
    <StatCard
      title="Splits"
      subtitle={`${made}/${attempts}`}
      value={`${((100 * made) / attempts).toFixed(1)}%`}
      iconComponent={
        <SvgIcon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
            overflow="visible"
          >
            <circle
              cx={10}
              cy={10}
              r={10}
              strokeWidth={2}
              // stroke="red"
              stroke={theme.palette.secondary.light}
            />
          </svg>
        </SvgIcon>
      }
    />
  );
}
