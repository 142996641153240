import {
  Autocomplete,
  TextField,
  Grid,
  Box,
  Typography,
  Container,
} from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { arsenalActions, useArsenalSlice } from '../Arsenal/slice';
import { selectBallSearchResults } from '../Arsenal/slice/selectors';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { AddBall } from 'app/components/AddBall';

interface Props {}
interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface BallSearchType {
  description: string;
  structured_formatting: StructuredFormatting;
  image: string;
}

export function BallSearch(props: Props) {
  const [value, setValue] = React.useState<BallSearchType | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  // const [options, setOptions] = React.useState<readonly BallSearchType[]>([]);
  const dispatch = useDispatch();
  useArsenalSlice();
  const ballSearchResults = useSelector(selectBallSearchResults);

  const options: BallSearchType[] | null =
    ballSearchResults.map<BallSearchType>((i: any) => ({
      extId: i.ball_id,
      description: i.ball_name,
      image: i.thumbnail_image,
      structured_formatting: {
        main_text: i.ball_name,
        secondary_text: i.brand_name,
      },
    }));

  return (
    <Container>
      <Box pt={2} maxWidth="95%">
        <Autocomplete
          getOptionLabel={option =>
            typeof option === 'string' ? option : option.description
          }
          // isOptionEqualToValue={(option, value) => option.extId === value.extId}
          filterOptions={x => x}
          options={options as any}
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={value}
          noOptionsText="No balls match"
          onChange={(event: any, newValue: BallSearchType | null) => {
            // setOptions(newValue ? [newValue, ...options] : options);
            setValue(newValue);
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
            dispatch(arsenalActions.ballSearchStart({ search: newInputValue }));
          }}
          renderInput={params => (
            <TextField {...params} label="Add a Ball" fullWidth />
          )}
          renderOption={(props, option: BallSearchType) => {
            const { /*key,*/ ...optionProps } = props;
            const matches = match(
              option.structured_formatting.main_text,
              inputValue,
            );
            const parts = parse(
              option.structured_formatting.main_text,
              matches,
            );
            return (
              <li {...optionProps}>
                <Grid container sx={{ alignItems: 'center' }}>
                  <Grid item sx={{ display: 'flex', width: '100' }}>
                    <Box
                      component="img"
                      sx={{ width: 100 }}
                      alt="ball"
                      src={`https://www.bowwwl.com${option.image}`}
                    />
                  </Grid>
                  <Grid
                    p={5}
                    item
                    sx={{ width: 'calc(100% - 110px)', wordWrap: 'break-word' }}
                  >
                    {parts.map((part, index) => (
                      <Box
                        key={index}
                        component="span"
                        sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                      >
                        {part.text}
                      </Box>
                    ))}
                    <Typography
                      variant="body2"
                      sx={{ color: 'text.secondary' }}
                    >
                      {option.structured_formatting.secondary_text}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            );
          }}
        />
      </Box>
      <AddBall
        newBall={ballSearchResults.find(
          b => (b as any).ball_name === value?.description,
        )}
      />
    </Container>
  );
}
