import { SensorSample } from 'api/sensor/types';
import { SensorData } from '../sensorValues';

/* --- STATE --- */
export interface TargetSensorState {
  lastSensorTime: number;
  selectedShot: number;
  rawValues?: SensorData;
  sensedShots?: [SensorSample[]];
  mode: Mode;
}

export enum Mode {
  Value = 'Value',
  Min = 'Min',
  Avg = 'Avg',
  MidAvg = 'MidAvg',
}
