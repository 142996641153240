import { Button } from '@mui/material';
import dayjs from 'dayjs';
import * as React from 'react';
import SensorOptionButton from './SensorOption';

export const SensorControls = (props: {
  onLeft: Function;
  onRight: Function;
  onControl: Function;
}) => {
  return (
    <>
      <Button
        variant="contained"
        onClick={() => props.onLeft()}
        sx={{
          opacity: 0.7,
          zIndex: 9999,
          bottom: '5%',
          left: '9%',
          marginLeft: '0%',
          position: 'absolute',
          transform: 'translate(-50%, 0%)',
          msTransform: 'translate(-50%, 0%)',
        }}
      >
        {'<<'}
      </Button>
      <SensorOptionButton
        handleClick={props.onControl}
        sx={{
          opacity: 0.7,
          zIndex: 9999,
          bottom: '5%',
          left: '50%',
          marginLeft: '0%',
          transform: 'translate(-50%, 0%)',
          msTransform: 'translate(-50%, 0%)',
          position: 'absolute',
        }}
      />
      <Button
        variant="contained"
        onClick={() => props.onRight()}
        sx={{
          opacity: 0.7,
          zIndex: 9999,
          bottom: '5%',
          left: '91%',
          marginLeft: '0%',
          position: 'absolute',
          transform: 'translate(-56%, 0%)',
          msTransform: 'translate(-56%, 0%)',
        }}
      >
        {'>>'}
      </Button>
    </>
  );
};
