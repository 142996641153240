/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// import { GlobalStyle } from 'styles/global-styles';
import { injectStyle } from 'react-toastify/dist/inject-style';
import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { useTranslation } from 'react-i18next';
import { TargetList } from './components/TargetList';
import { TargetSensor } from './pages/TargetSensor';
import { ThemeProvider, createTheme } from '@mui/material';
import { PatternEditor } from './pages/PatternEditor';
import { PatternCompare } from './pages/PatternCompare';
import { PricingPage } from './pages/PricingPage';
import { HomePage } from './pages/HomePage';
import { PatternViewer } from './pages/PatternViewer';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { LinkProps } from '@mui/material/Link';
import { Constants } from '../constants';
// import { CharterPage } from './pages/CharterPage/Loadable';
import { CharterPage } from './pages/CharterPage';
import { Sso } from './pages/Sso';
import { useSsoSlice } from './pages/Sso/slice';
import NavBar from './components/NavBarFullSide';
import ResponsiveAppBar from './components/ResponsiveAppBar';
import { AppActionsContext } from './components/ResponsiveAppBar/AppActionsContext';
import { useSelector } from 'react-redux';
import { selectSso } from './pages/Sso/slice/selectors';
import { clearAuthInfo } from './pages/Sso/slice/saga';
import { SetRecap } from './components/SetRecap';
import reportWebVitals from 'reportWebVitals';
import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import { ToastContainer } from 'react-toastify';
import { useNotificationsSlice } from './components/Notifications/slice';
import { useNetworkManagerSlice } from './components/NetworkManager/slice';
import { RecapsPage } from './pages/RecapsPage';
import { CurrentSetRecap } from './components/SetRecap/CurrentSetRecap';
import { RecapReview } from './pages/RecapReview';
import { BallSearch } from './pages/BallSearch';
import { Arsenal } from './pages/Arsenal';

const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & {
    href: RouterLinkProps['to'];
  }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (Material UI) -> to (react-router)
  return (
    <RouterLink
      ref={ref}
      to={href}
      /* target="_top" this causes full reload */ {...other}
    />
  );
});

export function App() {
  const { i18n } = useTranslation();
  const theme = createTheme({
    palette: {
      mode: 'light',
      background: {
        default: '#ffffff',
      },
      primary: {
        main: '#4a98e6',
      },
      secondary: {
        main: '#e6984a',
      },
      forwardOil: {
        // main: '#000',
        main: '#00f',
      },
      reverseOil: {
        // main: '#984ae6',
        main: '#f00',
      },
    },
    components: {
      MuiLink: {
        defaultProps: {
          component: LinkBehavior,
        } as LinkProps,
      },
      MuiButtonBase: {
        defaultProps: {
          LinkComponent: LinkBehavior,
        },
      },
    },
  });

  const [appActions, setAppActions] = React.useState([]);
  const appActionsValue = { appActions, setAppActions };
  const sso = useSelector(selectSso);
  useNotificationsSlice();
  useNetworkManagerSlice();

  if (typeof window !== 'undefined') {
    injectStyle();
  }

  return (
    <>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Helmet
            titleTemplate={`%s - ${Constants.BrandName}`}
            defaultTitle={Constants.BrandName}
            htmlAttributes={{ lang: i18n.language }}
          >
            <meta
              name="description"
              content="Improve your scores through bowling intelligence"
            />
          </Helmet>

          <AppActionsContext.Provider value={appActionsValue as any}>
            <Routes>
              <Route path="/" element={<ResponsiveAppBar />}>
                <Route index element={<HomePage />} />
                <Route path="recaps">
                  <Route path="review/:setId" element={<RecapReview />} />
                  <Route index element={<RecapsPage />} />
                </Route>
                <Route path="/sso/*" element={<Sso />} />
                <Route path="chart" element={<CharterPage />} />
                <Route path="game">
                  <Route path="recap" element={<CurrentSetRecap />} />
                </Route>
                <Route path="pricing" element={<PricingPage />} />
                <Route path="target">
                  <Route path="saved" element={<TargetList />} />
                  <Route path="sensor" element={<TargetSensor />} />
                  <Route index element={<PatternViewer />} />
                </Route>
                <Route path="pattern/edit" element={<PatternEditor />} />
                <Route path="pattern/Compare" element={<PatternCompare />} />
                <Route path="login" element={<Login />} />
                <Route path="logout" element={<Logout />} />
                <Route path="*" element={<NotFoundPage />} />
                <Route path="arsenal">
                  <Route path="search" element={<BallSearch />} />
                  <Route index element={<Arsenal />} />
                </Route>
              </Route>
            </Routes>
          </AppActionsContext.Provider>
          {/* <GlobalStyle /> */}
          <ToastContainer position="top-left" toastStyle={{ margin: 4 }} />
        </ThemeProvider>
      </BrowserRouter>
    </>
  );
}

function Login() {
  clearAuthInfo(true);
  window.location.replace(process.env.REACT_APP_LOGIN_URI as string);
  return null;
}

function Logout() {
  clearAuthInfo(true);
  window.location.replace(process.env.REACT_APP_LOGOUT_URI as string);
  return null;
}
