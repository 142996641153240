export async function searchBalls(searchTerm: string) {
  const response = await fetch(
    // `https://www.bowwwl.com/restapi/balls/v2?ball_name=${searchTerm}`,
    `${process.env.REACT_APP_API_URI}/arsenal/search?ball_name=${searchTerm}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('AWS_BEARER_TOKEN')}`,
        'Content-Type': 'application/json',
      },
      method: 'get',
    },
  );
  if (response.status >= 200 && response.status < 300) {
    return await response.json();
  }
  throw await response.json();
}
