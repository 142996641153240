const adjacency = {
  1: [2, 3],
  2: [1, 4, 5, 8],
  3: [1, 5, 6, 9],
  4: [2, 7, 8],
  5: [2, 3, 8, 9],
  6: [3, 9, 10],
  7: [4],
  8: [4, 5, 2],
  9: [5, 6, 3],
  10: [6],
};

export const isSplit = (leave: number[]) => {
  leave = leave.sort((a, b) => b - a);
  if (leave.length < 2 || leave.includes(1)) return false;
  const pinAdjacencies = adjacency[leave.shift() as number];
  const intersection = pinAdjacencies.filter(pin => leave.includes(pin));
  if (intersection.length) return isSplit(leave);
  return true;
};
