/**
 *
 * StatsStrikes
 *
 */
import * as React from 'react';
import { FrameSummary } from '../GameManager/slice/types';
import { StatCard } from '../StatCard';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material';
import { strikeStats } from 'utils/stats';

interface Props {
  frames: FrameSummary[][];
}

export function StatsStrikes(props: Props) {
  const { frames } = props;

  const theme = useTheme();
  const { total } = strikeStats(frames);
  return (
    <StatCard
      title="Strikes"
      subtitle="All Balls"
      value={total.toString()}
      iconComponent={
        <SvgIcon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 10"
            strokeWidth={1.5}
            stroke="currentColor"
          >
            <path
              d="M0 10 L10 -1 M0 0 L10 10"
              stroke-width="1"
              stroke={theme.palette.secondary.light}
            />
          </svg>
        </SvgIcon>
      }
    />
  );
}
