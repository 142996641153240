import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { NoAccounts } from '@mui/icons-material';
import { Stack, lighten, useTheme } from '@mui/material';
import { AppActionsContext } from './AppActionsContext';
import { useSelector } from 'react-redux';
import { selectSso } from 'app/pages/Sso/slice/selectors';
import { Logo } from '../Logo';
import { Constants } from '../../../constants';
import Notifications from '../Notifications';
import { Outlet } from 'react-router-dom';
// const links = ['Chart', 'Target', 'Pattern Viewer', 'Pattern Compare'];
const links = [
  { label: 'All Recaps', href: '/recaps' },
  { label: 'Arsenal', href: '/arsenal/search' },
  { label: 'Chart', href: '/chart' },
  { label: 'Current Recap', href: 'game/recap' },
  { label: 'Target', href: '/target' },
  { label: 'Sensor', href: '/target/sensor' },
  { label: 'Pattern Viewer', href: '/pattern/edit' },
  { label: 'Pattern Compare', href: '/pattern/compare' },
  { label: 'About', href: '/' },
];
const settings = ['Profile', 'Account', 'Dashboard'];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null,
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const theme = useTheme();
  const auth = useSelector(selectSso);
  const appActions = React.useContext(AppActionsContext);

  return (
    <>
      <AppBar
        position="static"
        sx={{ backgroundColor: lighten(theme.palette.secondary.main, 0) }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ display: { xs: 'flex' }, mr: 1 }}>
              <Logo />
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', lg: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', lg: 'none' },
                }}
              >
                {links.map(page => (
                  <MenuItem key={page.label} onClick={handleCloseNavMenu}>
                    <Button
                      // LinkComponent={Link}
                      key={page.label}
                      href={page.href}
                      onClick={handleCloseNavMenu}
                      fullWidth={true}
                      sx={{ justifyContent: 'left' }}
                    >
                      <Typography
                        sx={{ textTransform: 'none', wordWrap: 'break-word' }}
                      >
                        {page.label}
                      </Typography>
                    </Button>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              {Constants.BrandName}
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'flex', justifyContent: 'center' },
                overflow: 'auto',
              }}
            >
              {appActions.appActions}
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', lg: 'flex' } }}>
              {links.map(page => (
                <Button
                  key={page.label}
                  onClick={handleCloseNavMenu}
                  href={page.href}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {page.label}
                </Button>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Stack direction="row">
                <Notifications />
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    {auth.isAuthed && (
                      <Avatar
                        alt={auth.name}
                        src="/static/images/avatar/2.jpg"
                      />
                    )}
                    {!auth.isAuthed && <NoAccounts fontSize="large" />}
                  </IconButton>
                </Tooltip>
              </Stack>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map(setting => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
                {auth.isAuthed ? (
                  <Button href="/logout">
                    <Typography sx={{ textTransform: 'none' }}>
                      Logout
                    </Typography>
                  </Button>
                ) : (
                  <Button href="/login">
                    <Typography sx={{ textTransform: 'none' }}>
                      Login
                    </Typography>
                  </Button>
                )}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {!auth.loading && <Outlet />}
    </>
  );
}
export default React.memo(ResponsiveAppBar);
