import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { targetSensorSaga } from './saga';
import { Mode, TargetSensorState } from './types';
import { SagaInjectionModes } from 'redux-injectors';
import { SensorSample } from 'api/sensor/types';
import { groupBy } from '../util';
import { SensorData } from '../sensorValues';
export const initialState: TargetSensorState = {
  lastSensorTime: new Date(2024, 1, 1).valueOf(),
  selectedShot: 0,
  mode: Mode.Avg,
};

const slice = createSlice({
  name: 'targetSensor',
  initialState,
  reducers: {
    // FIXME: need to add in the missing values from the shortest array too
    newData(state, action: PayloadAction<SensorSample[]>) {
      state.lastSensorTime = new Date(action.payload[0].datetime).valueOf();
      const transformed: SensorData = groupBy(action.payload, 'sensorId');
      state.rawValues = transformed;
      const keyLengths = Object.entries(transformed)
        .map(entry => {
          return { key: entry[0], length: entry[1].length };
        })
        .sort((a, b) => b.length - a.length);

      const longestSensor = keyLengths.shift();
      const paried: any[] = [];

      if (longestSensor && longestSensor.key) {
        transformed[longestSensor.key].map(thisValue => {
          const thisDate = new Date(thisValue.datetime);
          const foundMatches = keyLengths.map(includedSensor => {
            const matchingValues = transformed[includedSensor.key].filter(
              thatValue => {
                const thatDate = new Date(thatValue.datetime);
                return (
                  Math.abs(thatDate.valueOf() - thisDate.valueOf()) < 1000 * 5
                );
              },
            );

            if (matchingValues.length === 1) {
              return matchingValues[0];
            } else return {};
          });

          paried.push(
            [thisValue].concat(foundMatches as unknown as SensorSample),
          );
        });
      }
      state.sensedShots = paried as any;
      state.selectedShot = 0;
    },

    previousShot(state) {
      if (state.selectedShot > 0) state.selectedShot--;
    },

    nextShot(state) {
      if (state.sensedShots && state.selectedShot < state.sensedShots.length) {
        state.selectedShot++;
      }
    },

    setMode(state, action: PayloadAction<Mode>) {
      state.mode = action.payload;
    },
    // addPatterns(state, action: PayloadAction<>) {
    //   state.patterns = action.payload;
    // },
    // setSelectedPatterns(state, action: PayloadAction<PatternData[]>) {
    //   state.patternsToCompare = action.payload;
    // },
  },
});

export const { actions: targetSensorActions } = slice;

export const useTargetSensorSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({
    key: slice.name,
    saga: targetSensorSaga,
    mode: SagaInjectionModes.RESTART_ON_REMOUNT,
  });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = usePaternCompareSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
