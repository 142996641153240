import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';
import { SensorSample } from 'api/sensor/types';
import { Mode } from './types';

const selectSlice = (state: RootState) => state.targetSensor || initialState;

export const selectTargetSensor = createSelector([selectSlice], state => state);

export const selectValues = createSelector([selectSlice], state => {
  if (!state.sensedShots) {
    return undefined;
  }

  const valueMode = (mode: Mode, str: string) => {
    switch (mode) {
      case Mode.Min:
        return byMin(str);
      default:
        return byAvg(str);
    }
  };

  return state.sensedShots
    .slice(state.selectedShot, state.selectedShot + 10)
    .map(pair =>
      pair.map(sample => ({
        ...sample,
        value:
          valueMode(state.mode, (sample as any)?.debug?.values) || sample.value,
      })),
    );
});

export const selectCurrentShotInfo = createSelector([selectValues], state => {
  if (!state || !state[0]) {
    return [];
  } else {
    return state[0]
      .filter(s => s.sensorDistance !== undefined)
      .sort(
        (a, b) => (b.sensorDistance as number) - (a.sensorDistance as number),
      );
  }
});

function byMin(values: string) {
  try {
    return values
      .split(',')
      .filter(v => v !== '')
      .map(v => Number.parseFloat(v))
      .filter(v => v !== 0)
      .sort((a, b) => a - b)[0];
  } catch (e) {
    return false;
  }
}

function byAvg(values: string) {
  try {
    const nonEmptyValues = values
      .split(',')
      .filter(v => v !== '')
      .map(v => Number.parseFloat(v))
      .filter(v => v !== 0);

    return (
      nonEmptyValues.reduce((acc, v) => acc + v, 0) / nonEmptyValues.length
    );
  } catch (e) {
    return false;
  }
}
