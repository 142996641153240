import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { arsenalSaga } from './saga';
import { ArsenalState } from './types';
import { SagaInjectionModes } from 'redux-injectors';

export const initialState: ArsenalState = {
  ballSearchResults: [],
  fetching: false,
};

const slice = createSlice({
  name: 'arsenal',
  initialState,
  reducers: {
    ballSearchStart(state, action: PayloadAction<{ search: string }>) {
      state.fetching = true;
    },
    ballSearchError(state, action: PayloadAction<{ error: string }>) {},
    ballSearchSuccess(state, action: PayloadAction<any>) {
      state.ballSearchResults = action.payload;
    },
  },
});

export const { actions: arsenalActions } = slice;

export const useArsenalSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({
    key: slice.name,
    saga: arsenalSaga,
    mode: SagaInjectionModes.RESTART_ON_REMOUNT,
  });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useArsenalSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
