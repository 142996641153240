/**
 *
 * RecapsPage
 *
 */
import { Container } from '@mui/material';
import { selectBowlingSet } from 'app/components/BowlingSet/slice/selectors';
import { SetList } from 'app/components/SetList';
import { useSetSearchSlice } from 'app/components/SetSearch/slice';
import { selectSetSearch } from 'app/components/SetSearch/slice/selectors';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface Props {}

export function RecapsPage(props: Props) {
  const { actions } = useSetSearchSlice();
  const dispatch = useDispatch();
  const sets = useSelector(selectSetSearch);

  React.useEffect(() => {
    dispatch(actions.listSets());
  }, [actions, dispatch]);

  if (sets.rows && sets.rows.length) {
    return (
      <Container sx={{ padding: 1 }}>
        <SetList rows={sets.rows} />
      </Container>
    );
  }
  return <div>Hello</div>;
}
