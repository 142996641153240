// import { take, call, put, select, takeLatest } from 'redux-saga/effects';
// import { paternCompareActions as actions } from '.';

import { call, delay, fork, put, select } from 'redux-saga/effects';
import { targetSensorActions as actions } from '.';
import { listSensor } from 'api/sensor';
import { selectTargetSensor } from './selectors';
import { SensorSample } from 'api/sensor/types';
import { notificationsActions } from 'app/components/Notifications/slice';
import React from 'react';

// function* doSomething() {}

export function* targetSensorSaga() {
  yield fork(poller);
}

export function* poller() {
  while (true) {
    const sensor: ReturnType<typeof selectTargetSensor> = yield select(
      selectTargetSensor,
    );
    console.log('POLLING');
    const result: SensorSample[] = (yield call(listSensor)).values;
    const lastSensedTime = new Date(result[0]?.datetime).valueOf();
    if (lastSensedTime > sensor.lastSensorTime) {
      console.log('NEW API DATA');
      yield put(actions.newData(result));
      yield put(
        notificationsActions.toast({
          component: React.createElement(
            'pre',
            {},
            `${JSON.stringify(result, null, 2)}`,
          ),
          options: {
            autoClose: 500,
            type: 'info',
          },
        }),
      );
    }
    yield delay(5000);
  }
  // const lane = yield select(selectLane);
  // const patterns = yield lane.patterns;
  // yield put(actions.addPatterns(patterns));
}
