/**
 *
 * StatsStrikes
 *
 */
import * as React from 'react';
import { FrameSummary } from '../GameManager/slice/types';
import { StatCard } from '../StatCard';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material';
import { strikeStats } from 'utils/stats';

interface Props {
  frames: FrameSummary[][];
}

export function StatsDoubles(props: Props) {
  const { frames } = props;

  const theme = useTheme();
  const { doubles, doublesAttempts } = strikeStats(frames);
  return (
    <StatCard
      title="Doubles"
      subtitle={`${doubles}/${doublesAttempts}  (${(
        (100 * doubles) /
        doublesAttempts
      ).toFixed(0)}%)`}
      value={doubles.toString()}
      iconComponent={
        <SvgIcon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 -3 22 20"
            strokeWidth={3}
            stroke="currentColor"
          >
            <path
              d="M 0 10 L 10 0 M 0 0 L 10 10 M 12 10 L 22 0 M 12 0 L 22 10"
              stroke-width="2"
              stroke={theme.palette.secondary.light}
            />
          </svg>
        </SvgIcon>
      }
    />
  );
}
