import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectBowlingSet } from '../BowlingSet/slice/selectors';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import { useBowlingSetSlice } from '../BowlingSet/slice';

interface Props {}

export function SetInfo(props: Props) {
  const set = useSelector(selectBowlingSet);
  const { actions: bowlingSetActions } = useBowlingSetSlice();
  const dispatch = useDispatch();

  if (!set.inProgress) {
    return <Box></Box>;
  }

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Box
        mb={5}
        display="flex"
        // width={{ sm: '90%', md: '50%' }}
        alignContent="center"
        // justifyContent="center"
        justifyItems="center"
        // justifySelf="center"
      >
        <Card elevation={5}>
          <CardContent>
            <Typography variant="body2">
              <Stack direction={{ xs: 'column', sm: 'row' }}>
                <ListItem>
                  <Typography variant="h5">Event</Typography>
                </ListItem>
                <ListItem>
                  <Typography>{set.event}</Typography>
                </ListItem>
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }}>
                <ListItem>
                  <Typography variant="h5">Date</Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    {new Date(set.startTime as number)
                      ?.toLocaleString()
                      .replace(',', ' ') || ''}
                  </Typography>
                </ListItem>
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }}>
                <ListItem>
                  <Typography variant="h5">Type</Typography>
                </ListItem>
                <ListItem>
                  <Typography>{set.type}</Typography>
                </ListItem>
              </Stack>
            </Typography>
          </CardContent>

          <CardActions>
            <Button
              variant="contained"
              onClick={() => dispatch(bowlingSetActions.abandon())}
            >
              Abandon
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Grid>
  );
}
