import { SensorSample } from './types';

export async function listSensor(): Promise<SensorSample[]> {
  const response = await fetch(`${process.env.REACT_APP_API_URI}/sensor`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('AWS_BEARER_TOKEN')}`,
      'Content-Type': 'application/json',
    },
  });
  if (response.status >= 200 && response.status < 300) {
    return await response.json();
  }
  throw await response.json();
}

export async function putSet(
  id: string,
  event: string,
  type: string,
  startTime: number,
  finishTime?: number,
) {
  const response = await fetch(`${process.env.REACT_APP_API_URI}/set/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('AWS_BEARER_TOKEN')}`,
      'Content-Type': 'application/json',
    },
    // credentials: 'include',
    method: 'put',
    body: JSON.stringify({
      id,
      event,
      type,
      startTime,
      finishTime,
    }),
  });
  if (response.status >= 200 && response.status < 300) {
    return await response.json();
  }
  throw await response.json();
}

export async function getSet(id: string) {
  const response = await fetch(`${process.env.REACT_APP_API_URI}/set/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('AWS_BEARER_TOKEN')}`,
      'Content-Type': 'application/json',
    },
    // credentials: 'include',
    method: 'get',
  });
  if (response.status >= 200 && response.status < 300) {
    return await response.json();
  }
  throw await response.json();
}
