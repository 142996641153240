/**
 *
 * AddBall
 *
 */
import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTargetLeftSlice, useTargetRightSlice } from '../lane/Lane/slice';
import { AppActionsContext } from '../ResponsiveAppBar/AppActionsContext';

interface Props {
  newBall?: any;
}

export function AddBall(props: Props) {
  const { newBall } = props;
  const [activeStep, setActiveStep] = React.useState(0);

  const [grit, setGrit] = React.useState<number | ''>('');
  const [layout, setLayout] = React.useState<string | ''>('');
  const [target, setTarget] = React.useState<string | ''>('');
  const [weight, setWeight] = React.useState('15');
  const [framesAtSurface, setFramesAtSurface] = React.useState(0.0);
  const [surfaceMode, setSurfaceMode] = React.useState('Polished');

  const handleChangeSurfaceMode = (
    event: React.MouseEvent<HTMLElement>,
    newMode: string,
  ) => {
    setSurfaceMode(newMode);
  };

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleEventTypeChange = (event: SelectChangeEvent) => {
    setWeight(event.target.value);
  };

  const dispatch = useDispatch();

  // React.useEffect(() => {
  //   if (bowlingSet.initializing) {
  //     setLane('');
  //     setLane('');
  //     setTarget('');
  //     setEvent('');
  //     setLaydown('');
  //     setEventType('appTesting');
  //     setAppActions(<></>);
  //   }
  // }, [bowlingSet.initializing, setAppActions]);

  if (!newBall) return <></>;
  console.log(newBall);

  return (
    <Box sx={{ maxWidth: 400 }} pt={2}>
      <Typography variant="h3">{newBall.ball_name}</Typography>
      <Typography variant="h5">{newBall.brand_name}</Typography>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step key="weight">
          <StepLabel>Weight</StepLabel>
          <StepContent>
            <Select
              labelId="add-ball-select-weight"
              id="add-ball"
              value={weight}
              label="Event Type"
              onChange={handleEventTypeChange}
            >
              {[8, 9, 10, 11, 12, 13, 14, 15, 16].map(v => (
                <MenuItem value={v.toFixed(0)}>{v.toFixed(0)}</MenuItem>
              ))}
            </Select>
            <Box sx={{ mb: 2 }}>
              <div>
                <Button
                  disabled={!weight}
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Next
                </Button>
                <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                  Back
                </Button>
              </div>
            </Box>
          </StepContent>
        </Step>
        <Step key="layout">
          <StepLabel>Layout</StepLabel>
          <StepContent>
            <TextField
              autoFocus
              label="Layout (optional)"
              placeholder="45x5x60"
              value={layout}
              onChange={e => setLayout(e.target.value)}
            />
            <Box sx={{ mb: 2 }}>
              <div>
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Next
                </Button>
                <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                  Back
                </Button>
              </div>
            </Box>
          </StepContent>
        </Step>
        <Step key="Surface">
          <StepLabel>Current Surface</StepLabel>
          <StepContent>
            <Stack direction={'column'} spacing={2}>
              <ToggleButtonGroup
                color="primary"
                value={surfaceMode}
                exclusive
                onChange={handleChangeSurfaceMode}
              >
                <ToggleButton size="small" value="Polished">
                  Polished
                </ToggleButton>
                <ToggleButton size="small" value="Sanded">
                  Sanded
                </ToggleButton>
              </ToggleButtonGroup>
              <TextField
                autoFocus
                label="Grit"
                value={grit}
                onChange={e => setGrit(parseInt(e.target.value) || 0)}
                inputProps={{
                  inputMode: 'numeric',
                }}
              />
              <TextField
                autoFocus
                label="Approx Frames @ current surface"
                value={framesAtSurface}
                onChange={e => setFramesAtSurface(parseFloat(e.target.value))}
                inputProps={{
                  inputMode: 'decimal',
                }}
              />
            </Stack>
            <Box sx={{ mb: 2 }}>
              <div>
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Next
                </Button>
                <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                  Back
                </Button>
              </div>
            </Box>
          </StepContent>
        </Step>
      </Stepper>
      {activeStep === 3 && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              // dispatch(
              //   actions.start({
              //     event: event,
              //     inProgress: true,
              //     startingLane: lane as number,
              //     type: eventType,
              //     target: parseFloat(target),
              //     laydown: parseFloat(laydown),
              //   }),
              // );
              // handleReset();
            }}
            sx={{ mt: 1, mr: 1 }}
          >
            Add ball to arsenal!
          </Button>
        </Paper>
      )}
    </Box>
  );
}
