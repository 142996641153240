/**
 *
 * StatsSpares
 *
 */
import * as React from 'react';
import { FrameSummary } from '../GameManager/slice/types';
import { StatCard } from '../StatCard';
import {
  Box,
  Container,
  Grid,
  Paper,
  Stack,
  styled,
  SvgIcon,
  Typography,
  useTheme,
  lighten,
} from '@mui/material';
import { spareDetailStats, spareStats } from 'utils/stats';
import { Frame } from '../ScoreLine/Frame';
import LanePinDeck from '../lane/LanePinDeck';

interface Props {
  frames: FrameSummary[][];
}

export function StatsLeaves(props: Props) {
  const { frames } = props;
  const theme = useTheme();
  const { combos } = spareDetailStats(frames);

  if (!combos || !combos.keys) return <></>;

  // const a = Array.from(combos.values());
  return (
    <Container>
      <Grid container>
        {combos.keys() &&
          Array.from(combos.values())
            .sort((a, b) => b.attempts - a.attempts)
            .map(combo => (
              <Grid item mt={3} m={0.5}>
                <Paper
                  elevation={3}
                  sx={{
                    backgroundColor: lighten(theme.palette.secondary.main, 0.3),
                  }}
                >
                  <Stack mb={1} pt={0.5} pb={2}>
                    <Container>
                      <svg
                        // overflow="visible"
                        viewBox={`0 0 42 42`}
                        preserveAspectRatio={'xMaxYMax meet'}
                        width={58}
                      >
                        <g
                          transform="translate(0 0) scale(1)"
                          overflow="visible"
                        >
                          <LanePinDeck pinState={combo.pinStatus} big />
                        </g>
                      </svg>
                      <Typography variant="body1" align="center" mt={0}>
                        {((100 * combo.made) / combo.attempts).toFixed(0)}%
                      </Typography>
                      <Typography
                        variant="body2"
                        align="center"
                        fontSize=".75em"
                      >
                        {combo.made}/{combo.attempts}
                      </Typography>
                    </Container>
                  </Stack>
                </Paper>
              </Grid>
            ))}
      </Grid>
    </Container>
  );
}
