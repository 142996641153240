/**
 *
 * SetRecap
 *
 */
import * as React from 'react';
// import { GameLine } from '../ScoreLine/GameLine';
import { Box, Container, Grid, Paper, Stack } from '@mui/material';
import { GameLine } from '../ScoreLine/GameLine';
import { StatsFirstBallAvg } from '../StatsFirstBallAvg';
import { StatsStrikes } from '../StatsStrikes';
import { StatsSpares } from '../StatsSpares';
import { StatsSpareLeaves } from '../StatsSpareLeaves';
import { SetInfo } from './SetInfo';
import { AppActionsContext } from '../ResponsiveAppBar/AppActionsContext';
import { FrameSummary } from '../GameManager/slice/types';
import { StatsSplits } from '../StatsSplits';
import { StatsMakables } from '../StatsMakables';
import { StatsDoubles } from '../StatsDoubles';
import { StatsLeaves } from '../StatsLeaves';

interface Props {
  games: FrameSummary[][];
}

export function SetRecap(props: Props) {
  const { games } = props;
  const { setAppActions } = React.useContext(AppActionsContext);
  React.useEffect(() => {
    setAppActions(<></>);
  }, [setAppActions]);

  return (
    // <Box
    //   p={0.5}
    //   display={'flex'}
    //   alignContent={'center'}
    //   justifyContent={'center'}
    // >
    <Grid container mt={10} p={1}>
      <Grid item xs={12} md={8}>
        <Container>
          <SetInfo />
          <Stack mb={3}>
            {games.map((frames, i) => (
              <Stack direction="row">
                <GameLine
                  game={`${i}`}
                  showPins
                  key={i}
                  frames={frames as any}
                  size={
                    window.innerWidth > 800
                      ? (window.innerWidth * (8 / 12)) / 13
                      : window.innerWidth / 13
                  }
                />
              </Stack>
            ))}
          </Stack>
        </Container>
        <Container>
          <Stack spacing={2} direction={'column'}>
            <StatsFirstBallAvg frames={games} />
            <StatsStrikes frames={games} />
            <StatsDoubles frames={games} />
            <StatsSplits frames={games} />
            <StatsMakables frames={games} />
            <StatsSpares frames={games} />
            <Box>
              <StatsLeaves frames={games} />
            </Box>
            {/* <StatsSpareLeaves frames={games} /> */}
          </Stack>
        </Container>
      </Grid>
      <Grid item xs={12} md={4}>
        Overflow
      </Grid>
    </Grid>
    // </Box>
  );
}
