/**
 *
 * StatsSpares
 *
 */
import * as React from 'react';
import { FrameSummary } from '../GameManager/slice/types';
import { StatCard } from '../StatCard';
import { SvgIcon, useTheme } from '@mui/material';
import { spareStats } from 'utils/stats';

interface Props {
  frames: FrameSummary[][];
}

export function StatsSpares(props: Props) {
  const { frames } = props;
  const theme = useTheme();
  const { made, attempts } = spareStats(frames);

  return (
    <StatCard
      title="Spares"
      subtitle={`${made}/${attempts}`}
      value={`${((100 * made) / attempts).toFixed(1)}%`}
      iconComponent={
        <SvgIcon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 10"
            strokeWidth={1.5}
            stroke="currentColor"
          >
            <path
              d="M0 10 L10 0"
              stroke-width="1"
              stroke={theme.palette.secondary.light}
            />
          </svg>
        </SvgIcon>
      }
    />
  );
}
