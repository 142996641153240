/**
 *
 * StatCard
 *
 */
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Grid,
  Container,
  Box,
  Stack,
  CardMedia,
  SvgIcon,
  Button,
  useTheme,
} from '@mui/material';
import * as React from 'react';

interface Props {
  title: String;
  subtitle?: String;
  value: String;
  iconComponent?: React.ReactElement;
}

export function StatCard(props: Props) {
  const { title, subtitle, value, iconComponent } = props;
  const theme = useTheme();
  return (
    // <Grid container justifyContent="center">
    <Card
      sx={{ display: 'flex', justifyContent: 'space-between' }}
      elevation={8}
    >
      <Box
        width={'100%'}
        m={1}
        sx={{ display: 'flex', flexDirection: 'row' }}
        alignItems={'center'}
        justifyContent={'space-between'}
        // alignContent={'center'}
      >
        <CardMedia>
          <Box
            sx={{ border: 2, borderColor: theme.palette.secondary.main }}
            p={1}
          >
            {iconComponent || <SvgIcon />}
          </Box>
        </CardMedia>
        <CardContent sx={{ flex: '1 0 auto', flexDirection: 'column' }}>
          <CardHeader
            title={title}
            subheader={subtitle}
            titleTypographyProps={{ fontSize: '1.25em' }}
          />
        </CardContent>
        <Box p={2}>
          <Typography variant="body1" fontSize="2em" fontWeight={500}>
            {value}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}

const STRIKE_SVG = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
 <path d='M0 10 L10 -1 M0 0 L10 10' stroke-width='1' stroke='black' />
 </svg>
  `;
const SPLIT_SVG = `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 10 10'> <circle cx='5' cy='5' r='4' stroke-width='.5' stroke='red' fill='none' /></svg>")`;
