/**
 *
 * Arsenal
 *
 */
import * as React from 'react';
import { useArsenalSlice } from './slice';
import { Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { BallSearch } from '../BallSearch';

interface Props {}

export function Arsenal(props: Props) {
  useArsenalSlice();

  return (
    <>
      <Helmet>
        <title>Arsenal</title>
        <meta name="description" content="Your bowling bag" />
      </Helmet>
      <Container>
        <BallSearch />
      </Container>
    </>
  );
}
