import { FrameSummary } from 'app/components/GameManager/slice/types';
import { isSplit } from './isSplit';
import { PinState } from 'app/components/lane/LanePinDeck';

export function spareStats(frames: FrameSummary[][]) {
  let total = 0;
  let attempts = 0;
  frames.forEach(game => {
    game.forEach((frame, i) => {
      if (frame.firstBallCount !== 10) {
        if (frame.firstBallCount + (frame.secondBallCount || 0) === 10) {
          total++;
        }
        attempts++;
      } else {
        if (i + 1 === 10) {
          if (frame.secondBallCount !== 10) {
            if (
              (frame.secondBallCount || 0) + (frame.thirdBallCount || 0) ===
              10
            ) {
              total++;
            }
            attempts++;
          }
        }
      }
    });
  });

  return { made: total, attempts };
}

export function splitStats(frames: FrameSummary[][]) {
  let total = 0;
  let attempts = 0;
  frames.forEach(game => {
    game.forEach((frame, i) => {
      const deliveries = frame.deliveries;
      const pinsUp =
        deliveries && deliveries.length
          ? ((deliveries[0] as any) || [])?.pinStatus?.pinStatus
              .filter(pin => pin.up)
              .map(pin => {
                return pin.pin;
              })
          : [];
      const wasSplit = isSplit(pinsUp);

      if (wasSplit) {
        if (frame.firstBallCount + (frame.secondBallCount || 0) === 10) {
          total++;
        }
        attempts++;
      } else {
        if (frame.firstBallCount === 10 && i + 1 === 10) {
          // FIXME
        }
      }
    });
  });

  return { made: total, attempts };
}

// export function strikeStats(frames: FrameSummary[][]) {
//   let total = 0;
//   frames.forEach(game => {
//     game.forEach((frame, i) => {
//       if (isStrike(frame)) {
//         total++;
//         if (i + 1 === 10 && frame.secondBallCount === 10) {
//           if (frame.thirdBallCount === 10) {
//             total += 2;
//           } else {
//             total++;
//           }
//         }
//       }
//     });
//   });
//   return { total };
// }

export function strikeStats(frames: FrameSummary[][]) {
  let total = 0;
  let doublesAttempts = 0;
  let doubles = 0;
  frames.forEach((game, gi) => {
    game.forEach((frame, i) => {
      if (isStrike(frame)) {
        doublesAttempts++;
        total++;
        const nextFrame = frames[gi][i + 1] || false;
        if (isStrike(nextFrame)) {
          doubles++;
        }
        if (i + 1 === 10 && frame.secondBallCount === 10) {
          doubles++;
          // doublesAttempts++;
          if (frame.thirdBallCount === 10) {
            // if (isStrike(frame)) {
            //   doubles++;
            // }
            total += 2;
          } else {
            total++;
          }
        }
      }
    });
  });
  return { doubles, doublesAttempts, total };
}

export function spareDetailStats(frames: FrameSummary[][]) {
  const combos: Map<
    string,
    { made: number; attempts: number; pinStatus: PinState[] }
  > = new Map();

  frames.forEach(game => {
    game.forEach((frame, i) => {
      if (
        frame.deliveries &&
        frame.deliveries[0] &&
        frame.firstBallCount !== 10
      ) {
        const pinStatus: PinState[] = frame.deliveries[0].pinStatus.pinStatus;
        const key = pinStatus
          .filter(p => p.up)
          .sort((a, b) => (a.pin || 0) - (b.pin || 0))
          .map(p => p.pin)
          .join();

        if (!combos.has(key)) {
          combos.set(key, { attempts: 0, made: 0, pinStatus });
        }
        const combo = combos.get(key) as any;
        if (frame.firstBallCount !== 10) {
          if (frame.firstBallCount + (frame.secondBallCount || 0) === 10) {
            combo.made++;
          }
          combo.attempts++;
        } else {
          // if (i + 1 === 10) {
          //   if (frame.secondBallCount !== 10) {
          //     if (
          //       (frame.secondBallCount || 0) + (frame.thirdBallCount || 0) ===
          //       10
          //     ) {
          //       combos[key].made++;
          //     }
          //     combos[key].attempts++;
          //   }
          // }
        }
      }
    });
  });

  return { combos };
}

function isStrike(frame: FrameSummary) {
  return frame ? frame.firstBallCount === 10 : false;
}
