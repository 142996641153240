import * as React from 'react';
import { Frame, Props as FrameProps } from './Frame';
import { Box, Container, Stack } from '@mui/material';
import { FrameLabel } from './FrameLabel';
import styled from '@emotion/styled';
import LanePinDeck from '../lane/LanePinDeck';
import { Padding } from '@mui/icons-material';
import { FrameNo } from '../GameManager/slice/types';

interface Props {
  frames: FrameProps[];
  size?: number;
  showPins?: boolean;
  game: string;
}

export function GameLine(props: Props) {
  const { frames, size, showPins } = props;

  const BigFrame = styled(Frame)({
    '& p .MuiTypography-body1': {
      fontSize: '3rem',
    },
  });
  return (
    <>
      {frames.map((frame, i) => (
        <Stack key={`${props.game}-frame-${i}`}>
          <Box
            overflow={'visible'}
            justifyContent="center"
            width={showPins ? size : 0}
            height={showPins ? size : 0}
            visibility={showPins ? 'visible' : 'hidden'}
          >
            <svg
              overflow={'auto'}
              viewBox={`0 0 45 45`}
              preserveAspectRatio={'xMaxYMax meet'}
              // viewBox={`0 0 41 37`}
              // height={size}
              // preserveAspectRatio="xMidYMid meet"
            >
              {frame.deliveries &&
                frame.deliveries[1] &&
                frame.deliveries[1].pinStatus && (
                  <g transform="translate(0 0) scale(1.0)">
                    <LanePinDeck
                      pinState={frame.deliveries[1].pinStatus.pinStatus as any}
                      big
                    />
                  </g>
                )}
            </svg>
          </Box>
          <BigFrame {...frame} frameNo={(i + 1) as FrameNo} size={size} />
          <FrameLabel frameNo={i + 1} key={`label ${i + 1}`} />
        </Stack>
      ))}
    </>
  );
}
