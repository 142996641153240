import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';
import { LaneState } from './types';
import _1 from '../../patterns/2022 USBC Open Championships (Team).json';
import _2 from '../../patterns/2022 USBC Open Championships (D & S).json';
import _3 from '../../patterns/DeadMansCurveV2.json';
import _4 from '../../patterns/Red Square.json';
import _5 from '../../patterns/2022 Starting House Pattern.json';
import _6 from '../../patterns/BeatenPath50.json';
import _7 from '../../patterns/Beaten Path V2.json';
import _8 from '../../patterns/Beaten Path (40 uL).json';
import _9 from '../../patterns/viper.json';
import _10 from '../../patterns/2023 PBA Don Johnson 40.json';
import _11 from '../../patterns/2021 SYC Boardwalk Bowl Medium.json';
import _12 from '../../patterns/2024 PBA MONACELLI 40 V1.json';
import _13 from '../../patterns/64th GOO - Team Event 2022.json';
import _14 from '../../patterns/chameleon-jr.json';
import _15 from '../../patterns/pba-chamelean-39.json';
import _16 from '../../patterns/2024 USBC Masters.json';
import _17 from '../../patterns/2023 USBC Jr Gold Tv.json';
import _18 from '../../patterns/2024 USBC Sr Masters.json';
import _19 from '../../patterns/2019-cheetha.json';
import _20 from '../../patterns/2024 ITC Women.json';
import _21 from '../../patterns/2024 ITC Women Team (Flex).json';
import _22 from '../../patterns/2019 pba earl anthony 43.json';
import _23 from '../../patterns/Sterling.json';
import _24 from '../../patterns/middle-road-v2.json';

import { parseRawLoads } from 'utils/pattern';
import { gameManagerActions } from 'app/components/GameManager/slice';
import {
  selectTargetLeftLaneReport,
  selectTargetRightLaneReport,
} from './selectors';
import {
  GameManagerState,
  AvailableCollectors,
} from 'app/components/GameManager/slice/types';
import { BOARD_WIDTH } from '../constants';

export const initialState: LaneState = {
  yScaleFactor: 4,
  laneHeight: window.innerHeight * 0.98,
  laydownBoard: 10,
  focalPointBoard: 10,
  targetBoard: 10,
  targetDistance: 15,
  launchAngle: 0,
  exitBoard: 10,
  exitDistance: 40,
  // selectedPattern: null,
  patterns: [
    _1,
    _2,
    _3,
    _4,
    _5,
    _6,
    _7,
    _8,
    _9,
    _10,
    _11,
    _12,
    _13,
    _14,
    _15,
    _16,
    _17,
    _18,
    _19,
    _20,
    _21,
    _22,
    _23,
    _24,
  ],
  showForwardPattern: true,
  showReversePattern: true,
  encodedLine: 'l=10.0&tb=10.0&td=15.0&eb=10.0&ed=40.0&fp=10.0',
};

const setLine: CaseReducer<LaneState, PayloadAction<string>> = (
  state,
  action,
) => {
  const param: URLSearchParams = new URLSearchParams(action.payload);
  state.laydownBoard = parse(param.get('l')) || state.laydownBoard;
  state.targetBoard = parse(param.get('tb')) || state.targetBoard;
  state.targetDistance = parse(param.get('td')) || state.targetDistance;
  state.exitBoard = parse(param.get('eb')) || state.exitBoard;
  state.exitDistance = parse(param.get('ed')) || state.exitDistance;
  state.focalPointBoard = parse(param.get('fp')) || state.focalPointBoard;
  state.encodedLine = encodeLine(state);
  const slope = (state.laydownBoard - state.exitBoard) / state.exitDistance;
  state.launchAngle = Math.atan((slope * BOARD_WIDTH) / 12);
};

export const sliceConfig = {
  initialState,

  reducers: {
    resetTarget(state, action: PayloadAction<void>) {
      return { ...state, ...initialState };
    },
    updateYScaleFactor(state, action: PayloadAction<any>) {
      state.yScaleFactor = action.payload;
    },
    makeMove(
      state,
      action: PayloadAction<{ laydownChange: number; targetChange: number }>,
    ) {
      state.laydownBoard += action.payload.laydownChange;
      state.targetBoard += action.payload.targetChange;
      const slope =
        (state.laydownBoard - state.targetBoard) / state.targetDistance;
      // state.targetBoard = state.laydownBoard - slope * state.targetDistance;
      state.exitBoard = state.laydownBoard - slope * state.exitDistance;
      state.focalPointBoard = state.laydownBoard - slope * 60;
      state.encodedLine = encodeLine(state);
      state.launchAngle = Math.atan((slope * BOARD_WIDTH) / 12);
    },
    setTargetAndLaydown(
      state,
      action: PayloadAction<{ laydown: number; target: number }>,
    ) {
      state.laydownBoard = action.payload.laydown;
      state.targetBoard = action.payload.target;
      state.targetDistance = 15;
      const slope =
        (state.laydownBoard - state.targetBoard) / state.targetDistance;
      // state.targetBoard = state.laydownBoard - slope * state.targetDistance;
      state.exitBoard = state.laydownBoard - slope * state.exitDistance;
      state.focalPointBoard = state.laydownBoard - slope * 60;
      state.encodedLine = encodeLine(state);
      state.launchAngle = Math.atan((slope * BOARD_WIDTH) / 12);
    },
    updateLaydownCalculateTarget(state, action: PayloadAction<any>) {
      state.laydownBoard = action.payload;
      const slope = (state.laydownBoard - state.exitBoard) / state.exitDistance;
      state.targetBoard = state.laydownBoard - slope * state.targetDistance;
      state.focalPointBoard = state.laydownBoard - slope * 60;
      state.encodedLine = encodeLine(state);
      state.launchAngle = Math.atan((slope * BOARD_WIDTH) / 12);
    },
    updateLaydownCalculateExit(state, action: PayloadAction<any>) {
      state.laydownBoard = action.payload;
      const slope =
        (state.laydownBoard - state.targetBoard) / state.targetDistance;
      // state.targetBoard = state.laydownBoard - slope * state.targetDistance;
      state.exitBoard = state.laydownBoard - slope * state.exitDistance;
      state.focalPointBoard = state.laydownBoard - slope * 60;
      state.encodedLine = encodeLine(state);
      state.launchAngle = Math.atan((slope * BOARD_WIDTH) / 12);
    },
    updateTargetCalculateExit(
      state,
      action: PayloadAction<{ board: number; distance: number }>,
    ) {
      state.targetBoard = action.payload.board;
      state.targetDistance = action.payload.distance;
      const slope =
        (state.laydownBoard - state.targetBoard) / state.targetDistance;
      state.exitBoard = state.laydownBoard - slope * state.exitDistance;
      state.focalPointBoard = state.laydownBoard - slope * 60;
      state.encodedLine = encodeLine(state);
      state.launchAngle = Math.atan((slope * BOARD_WIDTH) / 12);
    },
    updateTargetCalculateLaydown(
      state,
      action: PayloadAction<{ board: number; distance: number }>,
    ) {
      state.targetBoard = action.payload.board;
      state.targetDistance = action.payload.distance;
      const slope =
        (state.exitBoard - state.targetBoard) /
        (state.exitDistance - state.targetDistance);
      state.laydownBoard = state.targetBoard - slope * state.targetDistance;
      state.focalPointBoard = state.laydownBoard + slope * 60;
      state.encodedLine = encodeLine(state);
      state.launchAngle = Math.atan((-slope * BOARD_WIDTH) / 12);
    },
    updateExitCalculateTarget(
      state,
      action: PayloadAction<{ board: number; distance: number }>,
    ) {
      state.exitBoard = action.payload.board;
      state.exitDistance = action.payload.distance;
      const slope = (state.exitBoard - state.laydownBoard) / state.exitDistance;
      state.targetBoard = state.laydownBoard + slope * state.targetDistance;
      state.focalPointBoard = state.laydownBoard + slope * 60;
      state.encodedLine = encodeLine(state);
      state.launchAngle = Math.atan((-slope * BOARD_WIDTH) / 12);
    },
    updateExitCalculateLaydown(
      state,
      action: PayloadAction<{ board: number; distance: number }>,
    ) {
      state.exitBoard = action.payload.board;
      state.exitDistance = action.payload.distance;
      const slope =
        (state.exitBoard - state.targetBoard) /
        (state.exitDistance - state.targetDistance);
      state.laydownBoard = state.exitBoard - slope * state.exitDistance;
      state.focalPointBoard = state.laydownBoard + slope * 60;
      state.encodedLine = encodeLine(state);
      state.launchAngle = Math.atan((-slope * BOARD_WIDTH) / 12);
    },
    setLine,
    setPattern(state, action: PayloadAction<string>) {
      state.selectedPattern = state.patterns.find(
        i => i.name === action.payload,
      );
    },
    setShowForwardPattern(state, action: PayloadAction<boolean>) {
      state.showForwardPattern = action.payload;
    },
    setShowReversePattern(state, action: PayloadAction<boolean>) {
      state.showReversePattern = action.payload;
    },
    parseRawLoadsData(state, action: PayloadAction<any>) {
      console.log(JSON.stringify(parseRawLoads(action.payload), null, 2));
    },
  },
};

const leftConfig = {
  extraReducers: builder =>
    builder
      .addCase(
        'gameManager/beginEditDelivery',
        (state, action: PayloadAction<any>) => {
          state.save = state.encodedLine;
        },
      )
      .addCase(
        'gameManager/restoreGame',
        (
          state,
          action: PayloadAction<{
            lastDelivery: {
              targetLeftLane?: ReturnType<typeof selectTargetLeftLaneReport>;
            };
          }>,
        ) => {
          const forwardAction = {
            payload: action.payload.lastDelivery.targetLeftLane as any,
          };
          return setLine(state, forwardAction as any);
        },
      )
      .addCase(
        'gameManager/setEditDelivery',
        (
          state,
          action: PayloadAction<{
            targetLeftLane?: ReturnType<typeof selectTargetLeftLaneReport>;
          }>,
        ) => {
          const forwardAction = { payload: action.payload.targetLeftLane };
          return setLine(state, forwardAction as any);
        },
      )
      .addCase(
        'gameManager/finishEditDelivery',
        (state, action: PayloadAction<any>) => {
          const forwardAction = { payload: state.save };
          return setLine(state, forwardAction as any);
        },
      ),
};

const rightConfig = {
  extraReducers: builder =>
    builder
      .addCase(
        'gameManager/restoreGame',
        (
          state,
          action: PayloadAction<{
            lastDelivery: {
              targetRightLane?: ReturnType<typeof selectTargetRightLaneReport>;
            };
          }>,
        ) => {
          const forwardAction = {
            payload: action.payload.lastDelivery.targetRightLane as any,
          };
          return setLine(state, forwardAction as any);
        },
      )
      .addCase(
        'gameManager/setEditDelivery',
        (
          state,
          action: PayloadAction<{
            targetRightLane?: ReturnType<typeof selectTargetRightLaneReport>;
          }>,
        ) => {
          state.save = state.encodedLine;
          const forwardAction = { payload: action.payload.targetRightLane };
          return setLine(state, forwardAction as any);
        },
      )
      .addCase(
        'gameManager/finishEditDelivery',
        (state, action: PayloadAction<any>) => {
          const forwardAction = { payload: state.save };
          return setLine(state, forwardAction as any);
        },
      ),
};

const slice = createSlice({
  ...sliceConfig,
  name: 'lane',
});

export const { actions: laneActions } = slice;

export const useLaneSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};

const sliceLeft = createSlice({
  ...leftConfig,
  ...sliceConfig,
  name: 'targetLeftLane',
});

export const { actions: targetLeftLaneActions } = sliceLeft;

export const useTargetLeftSlice = () => {
  useInjectReducer({ key: sliceLeft.name, reducer: sliceLeft.reducer });
  return { actions: sliceLeft.actions };
};

const sliceRight = createSlice({
  ...rightConfig,
  ...sliceConfig,
  name: 'targetRightLane',
});

export const { actions: targetRightLaneActions } = sliceRight;

export const useTargetRightSlice = () => {
  useInjectReducer({ key: sliceRight.name, reducer: sliceRight.reducer });
  return { actions: sliceRight.actions };
};

function parse(s: string | null) {
  if (s) {
    return parseFloat(s);
  }
  return null;
}

function encodeLine(lane) {
  return new URLSearchParams({
    l: lane.laydownBoard.toFixed(1).toString(),
    tb: lane.targetBoard.toFixed(1).toString(),
    td: lane.targetDistance.toFixed(1).toString(),
    eb: lane.exitBoard.toFixed(1).toString(),
    ed: lane.exitDistance.toFixed(1).toString(),
    fp: lane.focalPointBoard.toFixed(1).toString(),
  }).toString();
}
