/**
 *
 * StatsFirstBallAvg
 *
 */
import * as React from 'react';
import { FrameSummary } from '../GameManager/slice/types';
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
} from '@mui/material';
import { StatCard } from '../StatCard';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
interface Props {
  frames: FrameSummary[][];
}

export function StatsFirstBallAvg(props: Props) {
  const { frames } = props;
  const theme = useTheme();

  let total = 0;
  let frameCount = 0;
  frames.forEach(game => {
    game.forEach(frame => {
      total += frame.firstBallCount;
      frameCount++;
    });
  });

  return (
    <StatCard
      title="First Ball Avg"
      subtitle="All Balls"
      value={(total / frameCount).toFixed(2)}
      iconComponent={<PowerSettingsNewIcon color="secondary" />}
    />
  );
}
