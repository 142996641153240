import { arsenalActions as actions } from '.';
import { call, put, debounce } from 'redux-saga/effects';
import { searchBalls } from 'api/ballSearch';

export function* arsenalSaga() {
  yield debounce(1500, actions.ballSearchStart.type, ballSearch);
}

export function* ballSearch(
  action: ReturnType<typeof actions.ballSearchStart>,
) {
  try {
    const response = yield call(searchBalls, action.payload.search);
    yield put(actions.ballSearchSuccess(response));
    console.log(response);
  } catch (error: any) {
    yield put(actions.ballSearchError(error.toString()));
  }
}
