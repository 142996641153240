import { getSet, listSets } from 'api/set';
import { call, put, takeLatest, delay } from 'redux-saga/effects';
import { setSearchActions as actions } from '.';
import dayjs from 'dayjs';

// function* doSomething() {}

export function* setSearchSaga() {
  yield takeLatest(actions.listSets.type, apiListSets);
  yield takeLatest(actions.getSetApiStart.type, apiGetSet);
}

export function* apiListSets() {
  yield put(actions.listSetsApiStart());
  yield call(listResource);
}

export function* listResource() {
  const apiResponse = yield call(listSetApi);
  yield put(actions.listSetsApiSuccess(apiResponse.sets));
}
export function* listSetApi() {
  while (true) {
    try {
      const apiResponse = yield call(listSets);
      return apiResponse;
    } catch (error) {
      yield put(actions.listSetsApiRetry(error));
      yield delay(20000);
    }
  }
}

export function* getSetApi(action) {
  while (true) {
    try {
      const games: any[] = [];
      const apiResponse = yield call(getSet, action.payload.id);
      (apiResponse.body as Array<any>)
        .sort((a, b) => dayjs(a.created).valueOf() - dayjs(b.created).valueOf())
        .forEach(item => games.push(JSON.parse(item.info)));

      return { ...apiResponse.body, games, id: action.payload.id };
    } catch (error) {
      yield put(actions.getSetApiRetry(error));
      yield delay(20000);
    }
  }
}

export function* apiGetSet(payload) {
  const result = yield call(getSetApi, payload);
  yield put(actions.getSetApiSuccess(result));
}
