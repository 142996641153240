/**
 *
 * Lane
 *
 */

import React, { useState } from 'react';
import LaneBoard from '../LaneBoard';
import LanePinDeck from '../LanePinDeck';
import LaneArrows from '../LaneArrows';
import RangeFinders from '../RangeFinders';
import { BOARD_WIDTH, LANE_WIDTH, PIN_DECK_LENGTH } from './constants';

//import styles from './styles.css';

import Marker from './Marker';
import { useSelector } from 'react-redux';
import { selectLane } from './slice/selectors';
import { Pattern } from '../Pattern';
import { PatternData } from '../Pattern/types';
import LaunchAngle from './LaunchAngle';
import { SensorData } from 'app/pages/TargetSensor/sensorValues';
import { getSensorLine } from 'app/pages/TargetSensor/util';
import { SensorSample } from 'api/sensor/types';
interface Props {
  showMarkerLabels?: boolean;
  hideSelectedMarker?: boolean;
  onBoardClick?: Function;
  selectedBoard?: number;
  panToFeet?: number;
  setOpenSetLaydown?: Function;
  setOpenSetTarget?: Function;
  setOpenSetExitpoint?: Function;
  selector: any;
  sensor?: [SensorSample[]];
  hideLine?: boolean;
}

export const Lane = (props: Props) => {
  const lane = props.selector;

  const [selectedBoard, setSelectedBoard] = useState(-1);
  const [selectedDistance, setSelectedDistance] = useState(-1);

  const generateBoards = height => {
    let boards: any[] = [];

    for (let i = 1; i < 40; i++) {
      const newBoard = (
        <LaneBoard
          key={`board-${i}`}
          width={BOARD_WIDTH}
          board={i}
          height={height}
          onClick={(b, d) => {
            setSelectedBoard(b);
            setSelectedDistance(d);
            props.onBoardClick && props.onBoardClick(b, d);
          }}
          isSelected={false}
        />
      );
      boards.push(newBoard);
    }
    return boards;
  };

  let height = 63 * 12;
  const width = LANE_WIDTH;
  const line = `M${BOARD_WIDTH * (39 - lane.focalPointBoard + 0.5)} ${30} L${
    BOARD_WIDTH * (39 - lane.laydownBoard + 0.5)
  } ${height} `;
  const boards = generateBoards(height);

  let transform = '';

  if (props.panToFeet) {
    const bottomYPoint = height - props.panToFeet * 12;
    transform = `matrix(1 0 0 1 0 -${bottomYPoint})`;
  }

  return (
    <>
      {/* <svg height={height + 8} width={width * 2.2}> */}
      <svg>
        {/* <defs>
          <pattern
            id="image"
            patternUnits="userSpaceOnUse"
            height={height}
            width={width}
          >
            <image
              x="0"
              y="0"
              width={width}
              height={height}
              xlinkHref={WallStreet}
              preserveAspectRatio="none"
            ></image>
          </pattern>
        </defs> */}
        {boards}
        {lane.selectedPattern && (
          <Pattern
            pattern={lane.selectedPattern as PatternData}
            showForwardPattern={lane.showForwardPattern}
            showReversePattern={lane.showReversePattern}
          />
        )}
        <LanePinDeck />
        <g transform={`translate(0,${60 * 12 + 34 - 16 * 12})`}>
          <LaneArrows height={height} boardWidth={BOARD_WIDTH} />
        </g>

        <g transform={`translate(0,${60 * 12 + 34 - 43 * 12})`}>
          <RangeFinders height={height} boardWidth={BOARD_WIDTH} />
        </g>

        {!props.hideLine && <path d={line} strokeWidth={0.5} stroke="green" />}
        <svg id="MarkerOverlay" x={0} y={PIN_DECK_LENGTH}>
          {}
          {selectedBoard !== -1 &&
            selectedDistance !== -1 &&
            !props.hideSelectedMarker && (
              <Marker
                showMarkerLabels={false}
                key="selected"
                board={selectedBoard}
                distance={selectedDistance}
                color="orange"
              />
            )}
          <Marker
            onClick={() => {
              if (props.setOpenSetLaydown) {
                props.setOpenSetLaydown(true);
              }
            }}
            showMarkerLabels={props.showMarkerLabels}
            key="Laydown"
            board={lane.laydownBoard}
            distance={0}
            color="blue"
          />
          <Marker
            onClick={() => {
              if (props.setOpenSetTarget) {
                props.setOpenSetTarget(true);
              }
            }}
            showMarkerLabels={props.showMarkerLabels}
            key="Target"
            board={lane.targetBoard}
            distance={lane.targetDistance}
            color="green"
          />
          <Marker
            onClick={() => {
              if (props.setOpenSetExitpoint) {
                props.setOpenSetExitpoint(true);
              }
            }}
            showMarkerLabels={props.showMarkerLabels}
            key="Exit"
            board={lane.exitBoard}
            distance={lane.exitDistance}
            color="red"
          />
          {props.sensor?.map((sensedShots, i) => {
            return sensedShots.map((sensedShot, ii) => {
              return (
                (sensedShot.value && (
                  <Marker
                    showMarkerLabels={false}
                    key={`marker_${i}_${ii}`}
                    board={sensedShot.value}
                    distance={sensedShot.sensorDistance || 15}
                    color={i ? '#0000ff52' : '#00005e'}
                    // opacity={i < 2 ? 1 : 0.6} // TODO: Group by sensor label and get last instead?
                    small
                  />
                )) || <div />
              );
            });
          })}
        </svg>
        <LaunchAngle angle={lane.launchAngle} x={20} y={height - 6} />
        {props.sensor && props.sensor[0].length > 1 && (
          <path
            d={getSensorLine(props.sensor[0], height)}
            strokeWidth={0.5}
            stroke="blue"
          />
        )}
      </svg>
    </>
  );
};

export default Lane;
