import { BOARD_WIDTH } from 'app/components/lane/Lane/constants';
import { SensorData } from './sensorValues';
import { SensorSample } from 'api/sensor/types';

export function groupBy<T>(collection: T[], key: keyof T) {
  const groupedResult = collection.reduce((previous, current) => {
    if (!previous[current[key]]) {
      previous[current[key]] = [] as T[];
    }

    previous[current[key]].push(current);
    return previous;
  }, {} as any); // tried to figure this out, help!!!!!
  return groupedResult;
}

export function getSensorLine(samples: SensorSample[], height) {
  try {
    const x1 = samples[0].value;
    const d1 = samples[0].sensorDistance || 0;
    const x2 = samples[1].value;
    const d2 = samples[1].sensorDistance || 0;

    const slope = (x2 - x1) / (d2 - d1);
    const laydown = x1 - slope * d1;

    const line = `M${BOARD_WIDTH * (39 - x2 + 0.5)} ${
      60 * 12 - 12 * d2 + 30
    } L${BOARD_WIDTH * (39 - laydown + 0.5)} ${height} `;
    return line;
  } catch (e) {
    return '';
  }
}
